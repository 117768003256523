<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        extension-height="10"
        color="grey darken-4"
        dark
      >
        <v-toolbar-title>Shari`ah Matters</v-toolbar-title>
      </v-toolbar>
      <v-list flat>
        <v-list-tile
          v-for="item in items"
          :key="item.title"
          :href="item.link"
          target="_blank"
        >
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-card>
  </panel>
</template>

<script>
import { createGetParams } from '@/rest'

const pageTitle = 'Shari`ah Matters'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Downloads', disabled: false, to: '/downloads',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      items: [],
      title: pageTitle,
    }
  },

  created: function () {
    const params = createGetParams({})
    this.$rest.get('getShariahMatter.php', params)
      .then((response) => {
        this.items = response.data
      }).catch(error => {
        console.log(error)
      })
  },
}
</script>
